import React from 'react'
import renderBlok from '@renderBlok'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box, useMediaQuery } from '@material-ui/core'
import PageContainer from '@system/page-container'
import ContentContainer from '@system/content-container'
import { calculateForegroundColor } from '@helpers/palette'
import multilinkToUrl from '../../helpers/multilink-to-url'
import ACC_THEME from '@themes'

const useStyles = makeStyles((theme) => ({
  linkWrapper: {
    textDecoration: 'none',
  },
  promoBarRoot: ({ bgColor }) => ({
    fontSize: '1rem',
    minHeight: '3.75rem',
    backgroundColor:
      (bgColor && bgColor.color) || theme.palette.background.iris,
    color: calculateForegroundColor(
      bgColor.color || theme.palette.background.iris
    ),
    fontWeight: theme.typography.fontWeightBold,
    '& a': {
      color: calculateForegroundColor(
        bgColor.color || theme.palette.background.iris
      ),
      textDecoration: 'underline',
      borderBottom: 'none',
      textTransform: 'uppercase',
    },
    '& a:hover': {
      borderBottom: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
  }),
  promoBarContent: ({ container }) => ({
    minHeight: '3.75rem',
    display: 'table',
    padding: `0.6rem ${container === 'content-container' ? 0 : '1rem'}`,
    textAlign: 'center',
    margin: '0 auto',
  }),
  contentItem: {
    display: 'table-cell',
    verticalAlign: 'middle',
    padding: '0 1rem',
    '&:first-child': {
      paddingLeft: '0',
    },
    '&:last-child': {
      paddingRight: '0',
    },
    '&:nth-child(2)': {
      borderLeft: '1px solid rgba(0,0,0,.2 )',
    },
    '&:nth-child(3)': {
      borderLeft: '1px solid rgba(0,0,0,.2 )',
    },
  },
  contentDivider: {},
}))

const PromoBar = ({ blok }) => {
  const {
    container,
    content,
    promoBarLink,
    mobileContent,
    linksDefaultOpenInSameWindow,
  } = blok
  const classes = useStyles(blok)
  const handHeldDevice = useMediaQuery(ACC_THEME.breakpoints.down('sm'))
  const contentToRender =
    mobileContent && mobileContent.length && handHeldDevice
      ? mobileContent
      : content
  const linkTarget = linksDefaultOpenInSameWindow ? '_blank' : null

  const LinkWrapper = (props) =>
    promoBarLink.cached_url ? (
      <a
        href={multilinkToUrl(promoBarLink)}
        target={linkTarget}
        className={classes.linkWrapper}
      >
        {props.children}
      </a>
    ) : (
      props.children
    )

  const Container = (props) => {
    switch (container) {
      case 'content-container':
        return <ContentContainer>{props.children}</ContentContainer>
      case 'page-container':
        return (
          <PageContainer backgroundColor="transparent">
            {props.children}
          </PageContainer>
        )
      case 'full-width':
        return props.children
      default:
        return props.children
    }
  }

  return (
    <SbEditable content={blok}>
      <LinkWrapper>
        <Box className={classes.promoBarRoot}>
          <Container>
            <Box className={classes.promoBarContent}>
              {!!contentToRender.length &&
                contentToRender.map((contentItem) => (
                  <Box key={contentItem._uid} className={classes.contentItem}>
                    {renderBlok(contentItem)}
                  </Box>
                ))}
            </Box>
          </Container>
        </Box>
      </LinkWrapper>
    </SbEditable>
  )
}

export default PromoBar
